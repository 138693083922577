import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React from 'react';

import PostRow from '../../templates/PostRow';

export default function BlogTabs({ posts }) {

  const renderPosts = (posts) => {
    return (
        <section>
          {posts.filter(({ node }) => node.frontmatter.ready).map(({ node }) =>
            <PostRow
              key={node.fields.slug}
              tag={node.frontmatter.tag}
              title={node.frontmatter.title || node.fields.slug} 
              slug={node.fields.slug}
              date={node.frontmatter.date}
              description={node.frontmatter.description}
              excerpt={node.excerpt}
              image={node.frontmatter.featuredImage} />
          )}
        </section>
    );
  };

  const categories = ['programming', 'leadership', 'reviews'];
  const filterBy = (tag) => posts.filter(({ node }) => { 
    const postTags = node.frontmatter.tag.split(' ');
    if (tag == 'other') {
      console.log(categories, postTags)
      return !categories.some(i => postTags.includes(i));
    } else {
      return postTags.includes(tag);
    }
  });

  return (
    <Tabs>
      <TabList>
        <Tab>All</Tab>
        <Tab>Programming</Tab>
        <Tab>Leadership</Tab>
        <Tab>Reviews</Tab>
        <Tab>Other</Tab>
      </TabList>

      <TabPanel>
        {renderPosts(posts)}
      </TabPanel>
      <TabPanel>
        {renderPosts(filterBy(categories[0]))}
      </TabPanel>
      <TabPanel>
        {renderPosts(filterBy(categories[1]))}
      </TabPanel>
      <TabPanel>
        {renderPosts(filterBy(categories[2]))}
      </TabPanel>
      <TabPanel>
        {renderPosts(filterBy('other'))}
      </TabPanel>
    </Tabs>
  );
}
